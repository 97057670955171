import React, { useState } from 'react';
import Logo from '../assets/logo.png';
import { FaPhoneAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
function Header() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {/* <header
      data-aos="fade-down"
      data-aos-offset="500"
      className=" fixed top-0 left-0 right-0 bg-naviblue  p-1 px-5 z-50 "
     
    >
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-white text-2xl font-bold">
          <img src={Logo} alt="" className="object-contain md:w-16 w-8" />
        </div>

        <nav className="flex space-x-4 md:text-3xl text-sm font-bold">
          <a href="#" className="text-white animate-pumping">
            Search
          </a>
          <a href="#" className="text-red-500  animate-pumping">
            Connect
          </a>
          <a href="#" className="text-green-500  animate-pumping">
            Consult
          </a>
        </nav>

        <nav className="flex space-x-4 md:text-2xl text-sm whitespace-nowrap font-bold">
          <a href="" className="text-white">
            Doc Search Hub
          </a>
        </nav>
      </div>
    </header> */}
      <nav className="bg-customBlue z-50">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <div className="text-white text-lg font-bold">
            <div className="text-white text-2xl font-bold">
              <Link to="/">
                <img
                  src={Logo}
                  alt="Logo"
                  className="object-contain md:w-16 w-8"
                />
              </Link>
            </div>
          </div>

          <div className="md:block hidden z-50">
            <nav className="flex items-center gap-5 p-4">
              <a href="#" className="text-white hover:text-neutral-light">
                Home
              </a>
              <a href="#" className="text-white hover:text-neutral-light">
                Find Doctor
              </a>
              <a href="#" className="text-white hover:text-neutral-light">
                Blog
              </a>
              <a href="#" className="text-white hover:text-neutral-light">
                Contact
              </a>
            </nav>
          </div>
          <div className="z-50">
            <a
              href="tel:+91 8823882929"
              className="bg-lighter  text-white p-3 rounded-xl  shadow-lg flex items-center justify-center hover:bg-blue-700"
            >
              Call Now
            </a>
          </div>
          <div className="md:hidden z-50">
            <button onClick={() => setIsOpen(!isOpen)} className="text-white">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Drawer Menu */}
        <div
          className={`fixed top-0 right-0 h-full w-64 bg-customBlue transform z-50 ${
            isOpen ? 'translate-x-0' : 'translate-x-full z-50'
          } transition-transform duration-300 ease-in-out md:hidden z-50`}
        >
          <div className="flex justify-end p-4">
            <button onClick={() => setIsOpen(false)} className="text-white">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <nav className="flex flex-col p-4 space-y-4">
            <a href="#" className="text-white hover:text-neutral-light">
              Home
            </a>
            <a href="#" className="text-white hover:text-neutral-light">
              Find Doctor
            </a>
            <a href="#" className="text-white hover:text-neutral-light">
              Blog
            </a>
            <a href="#" className="text-white hover:text-neutral-light">
              Contact
            </a>
          </nav>
        </div>
      </nav>
    </>
  );
}

export default Header;
