import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import departmentsData from '../../Data/departments.json'; // Ensure the path is correct
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaGlobe,
  FaMapMarkerAlt,
  FaPhoneAlt,
  FaEnvelope,
  FaWhatsapp,
  FaGoogle,
} from 'react-icons/fa';
import shuffle from 'lodash/shuffle';

const DepartmentDetail = () => {
  const { id } = useParams();
  const [department, setDepartment] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Find the department by ID
    const dept = departmentsData.find((d) => d.id === parseInt(id));
    if (dept) {
      // Shuffle the doctors array
      const shuffledDoctors = shuffle(dept.doctors);
      setDepartment({ ...dept, doctors: shuffledDoctors });
    }
  }, [id]);

  if (!department) return <div>Loading...</div>;

  // Function to filter doctors
  const filteredDoctors = department.doctors.filter(
    (doctor) =>
      doctor.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      doctor.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="relative">
      {/* Background Image with Gradient Overlay */}
      <div
        className="relative flex flex-col items-center justify-center h-[400px] z-10"
        style={{
          backgroundImage: `url(${department.image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-r from-darker via-original to-lighter opacity-70"></div>
        <div className="relative z-10 text-center text-white p-10">
          <h1 className="text-4xl font-bold mb-4">{department.name}</h1>
          <p className="mb-4">{department.description}</p>
        </div>
      </div>
      <div className="flex justify-center items-center  z-50">
        <div className="border-8  flex w-1/2 border-lighter">
          <input
            type="text"
            placeholder="Search doctors..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-3 w-full rounded-l bg-white shadow-2xl border-2 "
          />
          <button className="p-2 px-5 bg-naviblue  text-white">Search</button>
        </div>
      </div>

      <div className="flex flex-wrap mt-8">
        {filteredDoctors.length > 0 ? (
          filteredDoctors.map((doctor) => (
            <div
              className="flex flex-col justify-center w-screen items-center my-5 gap-10"
              key={doctor.id}
            >
              <div className=" relative justify-center items-center flex flex-col  rounded-xl shadow-lg p-3 max-w-xs md:max-w-6xl mx-auto border border-white bg-white">
                <div className="relative justify-center items-center flex flex-col md:flex-row rounded-xl ">
                  <div className="w-full md:w-1/3 bg-white grid place-items-center">
                    <img
                      src={doctor.image}
                      alt={doctor.name}
                      className="rounded-xl"
                    />
                  </div>

                  <div className="w-full md:w-2/3 bg-white flex flex-col space-y-5 p-3 px-10 ">
                    <div className="flex justify-center flex-col item-center w-full">
                      <h3 className="font-black text-gray-800 md:text-3xl text-xl text-center">
                        {doctor.name}
                      </h3>
                      <p className="md:text-sm text-gray-500 text-base text-center">
                        {doctor.specialization}
                      </p>
                    </div>
                    <div className="flex gap-5  justify-between flex-wrap items-start">
                      <p className='md:text-lg text-gray-500 text-base"'>
                        {doctor.Time}
                      </p>
                      <p className="text-gray-600">
                        <FaMapMarkerAlt className="inline mr-2" />
                        {doctor.address}
                      </p>{' '}
                    </div>
                    <div className="flex gap-5  justify-between flex-wrap items-start">
                      <p className="text-gray-600">
                        <FaEnvelope className="inline mr-2" />
                        {doctor.email}
                      </p>
                      <p className="text-gray-600">
                        <FaPhoneAlt className="inline mr-2" />
                        {doctor.phone}
                      </p>
                    </div>
                    <p className=" flex gap-5  justify-between flex-wrap items-start font-black text-gray-800">
                      <a
                        href={doctor.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-naviblue hover:underline text-xl mt-2"
                      >
                        <FaGlobe className="inline mr-2" />
                        Website
                      </a>

                      <div className="flex space-x-2 mt-2">
                        <a
                          href={doctor.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:underline text-xl"
                        >
                          <FaFacebook />
                        </a>
                        <a
                          href={doctor.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-pink-600 hover:underline text-xl"
                        >
                          <FaInstagram />
                        </a>
                        <a
                          href={doctor.google}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-red-600 hover:underline text-xl"
                        >
                          <FaGoogle />
                        </a>
                        <a
                          href={doctor.youtube}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-red-600 hover:underline text-xl"
                        >
                          <FaYoutube />
                        </a>
                        <a
                          href={doctor.whatsapp}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-green-600 hover:underline text-xl"
                        >
                          <FaWhatsapp />
                        </a>
                      </div>
                    </p>
                    <div className="flex gap-5 justify-between flex-wrap items-start">
                      <a
                        href={`tel:${doctor.phone}`}
                        className="inline-block bg-naviblue w-fit text-white px-3 py-2 rounded mt-2"
                      >
                        <FaPhoneAlt className="inline mr-2" />
                        Call Now
                      </a>
                      <a
                        href={doctor.map}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block bg-naviblue w-fit text-white px-3 py-2 rounded mt-2"
                      >
                        <FaMapMarkerAlt className="inline mr-2" />
                        Open Map
                      </a>
                    </div>
                  </div>
                </div>
                <div className="w-full  bg-white grid place-items-center">
                  <p className="md:text-lg text-gray-500 text-base text-justify">
                    {doctor.description}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="w-[100%]">
            <div class="gradient text-white min-h-screen flex items-center">
              <div class="container mx-auto p-4 flex text-center flex-wrap items-center ">
                <div class="w-full md:w-[100vw] text-center md:text-left p-4 flex justify-center flex-col">
                  <div class="text-xl md:text-3xl font-medium mb-4 py-10 text-center">
                    Oops. No doctors available
                  </div>
                  <div className="flex justify-center w-full">
                    <a
                      href="/"
                      class="border border-white rounded p-4 my-5 text-center w-fit"
                    >
                      Go Home
                    </a>{' '}
                  </div>
                </div>
              </div>
            </div>
          </p>
        )}
      </div>
    </div>
  );
};

export default DepartmentDetail;
