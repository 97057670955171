import React, { useState, useEffect } from 'react';
import DepartmentCard from './DepartmentCard';
import departmentsData from '../../Data/departments.json'; // Ensure the path is correct

const DepartmentList = () => {
  const [departments, setDepartments] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    // Load and sort departments data alphabetically by name
    const sortedDepartments = departmentsData.sort((a, b) =>
      a.name.localeCompare(b.name)
    );
    setDepartments(sortedDepartments);
  }, []);

  // Function to filter departments
  const filteredDepartments = departments.filter(
    (department) =>
      department.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      department.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="px-5   -mt-11">
      <div className="mb-8 flex justify-center ">
        <div className="border-8  flex md:w-1/2 w-full border-lighter">
          <input
            type="text"
            placeholder="Search departments..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="p-3 w-full rounded-l bg-white shadow-2xl border-2 "
          />
          <button className="p-2 px-5 bg-naviblue  text-white">Search</button>
        </div>
      </div>
      {filteredDepartments.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 ">
          {filteredDepartments.map((department) => (
            <DepartmentCard key={department.id} department={department} />
          ))}
        </div>
      ) : (
        <div className="flex justify-center mt-4">
          <p className="text-lg text-gray-600">No results found</p>
        </div>
      )}
    </div>
  );
};

export default DepartmentList;
