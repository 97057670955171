import React from 'react';

const Blog = () => {
  return (
    <div>
      <section className="w-[100vw">
        <div className="py-8 px-4 mx-auto lg:py-16 lg:px-6">
          <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
            <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-white">
              Our Blog
            </h2>
            <p className="font-light text-white">
              Stay updated with the latest news and insights from our team of
              experts.
            </p>
          </div>
          <div className="grid gap-8 lg:grid-cols-2">
            {/* Blog Article 1 */}
            <article
              className="p-6 rounded-lg border border-gray-200 shadow-md text-white"
              style={{
                background: 'linear-gradient(135deg, #017BC3, #1D2A5D)',
              }}
            >
              <div className="flex justify-between items-center mb-5 ">
                <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                  <svg
                    className="mr-1 w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
                  </svg>
                  News
                </span>
                <span className="text-sm">3 days ago</span>
              </div>
              <h2 className="mb-2 text-2xl font-bold tracking-tight">
                <a href="#">Innovations in Healthcare Technology</a>
              </h2>
              <p className="mb-5 font-light">
                Explore the latest advancements in healthcare technology and how
                they are transforming patient care.
              </p>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    className="w-7 h-7 rounded-full"
                    src="https://lh3.googleusercontent.com/p/AF1QipPfcpd2xEQRi8-t8xi68jWDViUp0ZylA_aMwI6l=s1360-w1360-h1020"
                    alt="Author avatar"
                  />
                  <span className="font-medium">Dr. Ritesh Singh Yadav</span>
                </div>
                <a
                  href="#"
                  className="inline-flex items-center font-medium text-primary-600 hover:underline"
                >
                  Read more
                  <svg
                    className="ml-2 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </article>

            {/* Blog Article 2 */}
            <article
              className="p-6 rounded-lg border border-gray-200 shadow-md text-white"
              style={{
                background: 'linear-gradient(135deg, #017BC3, #1D2A5D)',
              }}
            >
              <div className="flex justify-between items-center mb-5">
                <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                  <svg
                    className="mr-1 w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"
                      clipRule="evenodd"
                    ></path>
                    <path d="M15 7h1a2 2 0 012 2v5.5a1.5 1.5 0 01-3 0V7z"></path>
                  </svg>
                  Case Study
                </span>
                <span className="text-sm">1 week ago</span>
              </div>
              <h2 className="mb-2 text-2xl font-bold tracking-tight">
                <a href="#">Improving Patient Outcomes with AI</a>
              </h2>
              <p className="mb-5 font-light">
                Learn how artificial intelligence is being utilized to enhance
                patient outcomes in various medical fields.
              </p>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    className="w-7 h-7 rounded-full"
                    src="https://lh3.googleusercontent.com/p/AF1QipPxhaJupNs7Dk9ThPGO68squ3tKebqzMbd6ezOw=s1360-w1360-h1020"
                    alt="Author avatar"
                  />
                  <span className="font-medium">Dr. Meera Soni</span>
                </div>
                <a
                  href="#"
                  className="inline-flex items-center font-medium text-primary-600 hover:underline"
                >
                  Read more
                  <svg
                    className="ml-2 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </article>

            {/* Blog Article 3 */}
            <article
              className="p-6 rounded-lg border border-gray-200 shadow-md text-white"
              style={{
                background: 'linear-gradient(135deg, #017BC3, #1D2A5D)',
              }}
            >
              <div className="flex justify-between items-center mb-5">
                <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                  <svg
                    className="mr-1 w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M2 5a2 2 0 012-2h8a2 2 0 012 2v10a2 2 0 002 2H4a2 2 0 01-2-2V5zm3 1h6v4H5V6zm6 6H5v2h6v-2z"></path>
                  </svg>
                  Case Study
                </span>
                <span className="text-sm">2 weeks ago</span>
              </div>
              <h2 className="mb-2 text-2xl font-bold tracking-tight">
                <a href="#">Telemedicine: The Future of Doctor Consultations</a>
              </h2>
              <p className="mb-5 font-light">
                Understand the role of telemedicine in providing accessible and
                efficient consultations, no matter the location.
              </p>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    className="w-7 h-7 rounded-full"
                    src="https://lh3.googleusercontent.com/p/AF1QipP0TmFsAyfrOXANB7J5bohCbWagx0E5hMxKO4Ss=s1360-w1360-h1020"
                    alt="Author avatar"
                  />
                  <span className="font-medium">Dr. Vinod Rajput</span>
                </div>
                <a
                  href="#"
                  className="inline-flex items-center font-medium text-primary-600 hover:underline"
                >
                  Read more
                  <svg
                    className="ml-2 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </article>

            {/* Blog Article 4 */}
            <article
              className="p-6 rounded-lg border border-gray-200 shadow-md text-white"
              style={{
                background: 'linear-gradient(135deg, #017BC3, #1D2A5D)',
              }}
            >
              <div className="flex justify-between items-center mb-5">
                <span className="bg-primary-100 text-primary-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded">
                  <svg
                    className="mr-1 w-3 h-3"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zM4 5a1 1 0 011-1h10a1 1 0 011 1v6H4V5zm0 7h12v3a1 1 0 01-1 1H5a1 1 0 01-1-1v-3z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  Tips
                </span>
                <span className="text-sm">1 month ago</span>
              </div>
              <h2 className="mb-2 text-2xl font-bold tracking-tight">
                <a href="#">How to Choose the Right Doctor for Your Needs</a>
              </h2>
              <p className="mb-5 font-light">
                Discover the essential steps in selecting the best doctor for
                your medical condition, location, and preferences.
              </p>
              <div className="flex justify-between items-center">
                <div className="flex items-center space-x-4">
                  <img
                    className="w-7 h-7 rounded-full"
                    src="https://lh3.googleusercontent.com/p/AF1QipMPXyzJkJ9ALQuyQs8tm1mYWehohN0A6MDi2EM=s1360-w1360-h1020"
                    alt="Author avatar"
                  />
                  <span className="font-medium">Dr. Suryansh Jain</span>
                </div>
                <a
                  href="#"
                  className="inline-flex items-center font-medium text-primary-600 hover:underline"
                >
                  Read more
                  <svg
                    className="ml-2 w-4 h-4"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </div>
            </article>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
