import React from 'react';

const Aboutus = () => {
  return (
    <section class="">
      <div class="py-12 bg-white w-[100vw]">
        <div class=" mx-auto px-4 sm:px-6 lg:px-8">
          <div class="lg:text-center">
            <h2 class="font-heading mb-4 bg-blue-100 text-blue-800 px-4 py-2 rounded-lg md:w-64 md:mx-auto text-xs font-semibold tracking-widest text-black uppercase title-font">
              Why choose Doc Search Hub?
            </h2>
            <p class="font-heading mt-2 text-3xl leading-8 font-semibold tracking-tight text-gray-900 sm:text-4xl">
              Your trusted platform for finding and connecting with doctors.
            </p>
            <p class="mt-4 max-w-2xl text-lg text-gray-500 lg:mx-auto">
              At Doc Search Hub, we simplify your healthcare journey by allowing
              you to search for the best doctors, connect with them seamlessly,
              and consult for expert advice—all in one place.
            </p>
          </div>

          <div class="mt-10">
            <dl class="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              <div class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                    <img src="https://i0.wp.com/dehayf5mhw1h7.cloudfront.net/wp-content/uploads/sites/151/2015/04/30152533/quicksearch.png" />
                  </div>
                  <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">
                    Quick Search
                  </p>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  Our intuitive search system helps you quickly find doctors by
                  specialty, location, or name, ensuring you get the right care.
                </dd>
              </div>
              <div class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKn8F_r4wWDJc93l0r0fCGZHz5uo_WYczJ6g&s" />
                  </div>
                  <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">
                    Direct Connect
                  </p>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  Easily connect with your chosen doctors through our secure
                  communication platform, bridging the gap between patients and
                  healthcare providers.
                </dd>
              </div>
              <div class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                    <img src="https://cdn.prod.website-files.com/5efa135521cb20136a174cb2/5efa16cc0592e06b785dc106_ECSB-Logo.png" />
                  </div>
                  <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">
                    Easy Consultation
                  </p>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  Consult with specialists and get expert advice from the
                  comfort of your home, making healthcare more accessible.
                </dd>
              </div>
              <div class="relative">
                <dt>
                  <div class="absolute flex items-center justify-center h-12 w-12 rounded-md bg-primary-500 text-white">
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3xX0Et3RH6ZzU9xjd832aAj8lHjDPcYw8lw&s" />
                  </div>
                  <p class="font-heading ml-16 text-lg leading-6 font-bold text-gray-700">
                    Comprehensive Profiles
                  </p>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  Detailed doctor profiles with qualifications, specialties,
                  patient reviews, and more to help you make informed decisions.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Aboutus;
