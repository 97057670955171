import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Hero from '../Pages/Landingpage/Hero';
import DepartmentDetail from '../Pages/Department/DepartmentDetail';
import Aboutus from '../Pages/About/Aboutus';
import Blog from '../Pages/Blog/Blog';

const AppRoutes = () => {
  return (
    <Router>
      <div className="flex justify-center items-center overflow-x-hidden">
        <div className="max-w-[1920px]">
          <Header />
          <Routes>
            <Route path="/" element={<Hero />} />
            {/* <Route path="/departments/:name" component={DepartmentDetail} /> */}
            <Route path="/department/:id" element={<DepartmentDetail />} />
            <Route path="/about" element={<Aboutus/>} />
            <Route path="/blog" element={<Blog/>} />
          </Routes>
          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default AppRoutes;
