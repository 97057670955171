import React from 'react';
import { Link } from 'react-router-dom';

const DepartmentCard = ({ department }) => {
  return (
    <Link
      to={`/department/${department.id}`}
      data-aos="flip-up"
      data-aos-offset="300"
      data-aos-easing="ease-in-sine"
      data-aos-duration="500"
      className="block w-full cursor-pointer hover:text-naviblue"
    >
      <div className="max-w-sm w-full h-full rounded-lg overflow-hidden shadow-lg bg-white transform transition-transform hover:scale-105 m-4">
        <div
          className="relative h-40 bg-cover bg-center"
          style={{
            backgroundImage: `url(${department.image})`,
            transition: 'opacity 0.5s ease',
            opacity: 0.8,
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-t from-black opacity-40"></div>
        </div>
        <div className="px-6 py-4">
          <div className="font-bold text-xl mb-2">{department.name}</div>
          <p className="text-gray-700 text-base mb-4">
            {department.description}
          </p>
          <div className="text-naviblue hover:underline">View Doctors</div>
        </div>
      </div>
    </Link>
  );
};

export default DepartmentCard;
