import React from 'react';
import Banner from './Banner';

import DepartmentList from '../Department/DepartmentList';
import Blog from '../Blog/Blog';

const Hero = () => {
  return (
    <div>
      <Banner />
      <DepartmentList classname='-mt-20' />
      <Blog />
    </div>
  );
};

export default Hero;
