import React from 'react';
import image1 from '../../assets/Group-bner.png';

const Banner = () => {
  return (
    <div>
      <section
        className=" h-screen  w-[100%] bg-cover bg-no-repeat bg-center z-0 md:bg-dekstopbanner bg-bannermobail"
        // style={{ backgroundImage: `url(${image1})` }}
      >
        <div className="absolute inset-0 flex flex-col justify-center items-center ">
          <div className="container text-center">
            {/* Add any overlay content here */}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
